// photos located under packages/xolvio-website/static/assets/images/team
const members = [
  {
    position: "CHIEF OPERATING OFFICER",
    name: "Shawn Goertzen",
    photo: "goertzen.jpg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Igor Pasieczny",
    photo: "pasieczny.jpg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Daniel Strak",
    photo: "strak.jpg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Sam Combs",
    photo: "combs.jpg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Andzelo Mazurek",
    photo: "mazurek.jpg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Przemysław Gierski",
    photo: "gierski.jpeg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Michał Opidowicz",
    photo: "opidowicz.jpeg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Christopher Goldman",
    photo: "goldman.jpg"
  },
  {
    position: "SENIOR SOFTWARE ENGINEER",
    name: "Joshua Jenson",
    photo: "jenson.jpg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Piotr Siemieniuk",
    photo: "siemieniuk.png"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Damian Stojaniuk",
    photo: "stojaniuk.jpg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Tomasz Gierczynski",
    photo: "gierczynski.jpeg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Tomasz Wilczek",
    photo: "wilczek.jpeg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Filip Mazur",
    photo: "mazur.png"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Philip Toms",
    photo: "toms.jpeg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Rafal Cywinski",
    photo: "cywinski.jpeg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Daniel Alberski",
    photo: "alberski.jpg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Mateusz Kulągowski",
    photo: "kulagowski.jpg"
  },
  {
    position: "SOFTWARE ENGINEER",
    name: "Dominik Lasek",
    photo: "lasek.jpg"
  },
  {
    position: "QA ENGINEER",
    name: "Lidia Gandecka",
    photo: "gandecka.jpg"
  },
  {
    position: "UX DESIGNER",
    name: "Tarek Quereshi",
    photo: "quereshi.jpg"
  },
  {
    position: "BUSINESS DEVELOPMENT & MARKETING MANAGER ",
    name: "Marek Nalikowski",
    photo: "nalikowski.jpg"
  },
];

const pageData = {
  tout: {
    heading: "/zol·vio/",
    content:
      "Our vision is to eliminate the problematic and mundane so the interesting can flourish. " +
      "Our mission is to help organizations achieve our vision through our products and services."
  },

  quote: {
    body: "Everything should be made as simple as possible, but not simpler.",
    signature: "Albert Einstein"
  },

  hero: {
    height: "693px",
    subheading: "WHAT WE DO?",
    heading:
      "We love to do interesting things and eliminate the mundane issues that get in the way.",
    headingHighlights: "love to",
    textBold:
      "In all of our endeavours, we strive to deeply understand the domain we're working in, to remove superfluous " +
      "processes and practices, and to automate repeatable tasks. We relentlessly try to reduce problems to their " +
      "elemental form and come up with solutions that are as simple and as elegant as possible.",
    textRegular:
      "By doing this, we've found that not only can we make work more interesting and enjoyable, but we can " +
      "massively improve quality and speed. We want to share this epiphany with others and have them reap the same " +
      "benefits."
  },

  firstSection: {
    imagePosition: "left",
    heading:
      "We believe at our core that investing in quality is the best way to get speed.",
    headingHighlights: "believe",
    body:
      "Though it may seem counter-intuitive, we've found that by focusing on quality practices we can achieve " +
      "lightning-fast delivery speeds. We strive to identify and make strategic investments in areas such as " +
      "software architecture, testing automation, training teams to have higher quality skills to perform " +
      "higher quality jobs, and improving holistic processes and practices to streamline throughput.",
    buttonText: "Discover our services",
    buttonUrl: "/services"
  },

  secondSection: {
    subheading: "WHAT WE DO?",
    imagePosition: "right",
    heading: "We believe in giving back to the community that we take from.",
    headingHighlights: "believe",
    body:
      "We use Open Source Software in many of our engagements and we love to give back. We encourage our staff " +
      "to publish our work, and to also contribute to other community projects. We also love to share our experience " +
      "and knowledge through our blog and our #QualityFaster guide."
  },

  leaders: {
    profiles: [
      {
        position: "CEO & SOFTWARE ARCHITECT",
        name: "Sam Hatoum",
        photo: "hatoum.jpg",
        description:
          "Xolvio founder, Sam is a thought leader on Software Development Life Cycle (SDLC) with 20+ years of expertise in Event Sourcing, distributed systems, Behavior-Driven Development (BDD), and Agile transformations with a proven track record of delivery at lightning speed." +
          "\n\n" +
          "Obsessed with the field of specifications and the transition from high-level collaboration to low-level specs, he champions the Quality Faster methodology that allows Xolvio to quadruple speed of development without sacrificing quality." +
          "\n\n" +
          "Sam is also the product owner for Xolvio's startup XSpecs, a platform that helps write and maintain specs reflecting the state of products, synchronized across dev tools."
      },
      {
        position: "CHIEF TECHNICAL ARCHITECT",
        name: "Łukasz Gandecki",
        photo: "gandecki.jpg",
        description:
          "Łukasz is a polyglot developer that started his career as a systems administrator and found his passion " +
          "for software quality and testing while working for companies such as Audi of America and 2K." +
          "\n\n" +
          "Today he provides consulting services and training for Xolvio clients, and is very active in the OSS " +
          "community, especially when it comes to developer tooling, testing and quality related projects."
      }
    ],
    title: "Meet our team",
    subheading: "TEAM"
  },

  team: {
    subheading: "TEAM",
    heading: "Creativity, collaboration and passion."
  }
};

module.exports = {
  members,
  pageData
};
