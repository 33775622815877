import {
    colors,
    CenteredContentWrapper,
    device,
    EmphasizedText,
    Hero,
    PageHeader,
    TextBody,
    spacing,
    ConfettiBigSectionWrapper,
    TextToutFullwidth,
    Team,
    Leaders,
    TwoColumnsWithImage,
    ImageConfettiWrapper,
    TwoColumnsWrapper,
    Subheading,
    SectionHeader,
    TwoColumnContentNodeWrapper as ContentNodeWrapper,
    ButtonInlineLink
} from "@xolvio/xolvio-ui";
import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { contactUsFooterProps } from "../pages-content/homepage";
import { pageData, members } from "../pages-content/about-us";
import {BlogWrapper} from "./Homepage";
import {MoveHack} from "./Services";
import {HeroContent} from './Services/ServicePageTemplate'
import Posts from './blog/Posts/Posts'

const addElementWithBackgroundImageToObject = images => obj => {
  const imageNode = images.find(image => image.base === obj.photo);
  return {
    ...obj,
    elementWithBackgroundImage: (
      <BackgroundImage
        fluid={imageNode && imageNode.childImageSharp.fluid}
        durationFadeIn={200}
        style={{
          height: "100%",
          backgroundSize: "cover"
        }}
      />
    )
  };
};

const Page = ({ data }) => {
  const teamImages = data.team.nodes;
  const leaderProfiles = pageData.leaders.profiles.map(
    addElementWithBackgroundImageToObject(teamImages)
  );

  return (
    <>
      <ToutWrapper>
        <ToutContent>
          <ToutHeader>{pageData.tout.heading}</ToutHeader>
          <ToutTextBody style={{textAlign: 'center'}}>
            We are software architects & expert builders that deliver higher quality software, faster. <br />
            Our mission is to help you build extensible systems that scale infinitely.
          </ToutTextBody>
        </ToutContent>
      </ToutWrapper>

      <MoveHack top={'150px'} topMobile={'100px'}/>

      <Hero
        inline
        height={"693px"}
        subheading={"WHAT WE DO"}
        heading={
          <InlineHeroWithTwoColumnsHeader>
            <EmphasizedText text="We eliminate the problematic and mundane so the interesting can flourish." highlight={"flourish"} />
          </InlineHeroWithTwoColumnsHeader>
        }
        children={
          <HeroContentWrapper>
            <div />

            <HeroContent margin="110" marginTablet="120">
              <TextBody bold>
                Taking cue from the industry's unsung best practices for high software
                quality, we strive to build on greatness and forge the future of software development.
              </TextBody>
              <TextBody>
                Our goal is to deliver future-proof solutions that enable you to focus
                on continuously delivering business value, instead of fighting endless technical issues.
              </TextBody>
            </HeroContent>
          </HeroContentWrapper>
        }
      />

      <MoveHack top="50px" topMobile="125px" />

      <TwoColumnsWithImage
        imagePosition={pageData.firstSection.imagePosition}
        imageNode={
          <ImageConfettiWrapper
            elementWithBackgroundImage={
              <BackgroundImage
                fluid={data.building.childImageSharp.fluid}
                durationFadeIn={200}
              />
            }
          />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <TwoColumnSectionHeader style={{textAlign: 'left'}}>
              <Subheading>ABOUT US</Subheading>
              <EmphasizedText text="Software architects & expert builders" highlight="builders" />
            </TwoColumnSectionHeader>
            <Text>
              We want to help you build a software edifice that you will thrive
              in, not another legacy system of tomorrow. Over many years of
              consulting and development work we have perfected an approach that
              enables rapid, scalable delivery — the <ButtonInlineLink as="a" href={'/how-we-work/'} style={{padding: 0}}>Quality Faster</ButtonInlineLink> methodology. This
              allows us to increase delivery velocity by 400% compared to typical
              agile teams, no matter the complexity of the project.
            </Text>
          </TwoColumnContentNodeWrapper>
        }
      />

      <Spacing />

      <CenteredContentWrapper>
        <Leaders {...pageData.leaders} profiles={leaderProfiles} />
        <Spacing />
        <TeamTitleWrapper>
          <Subheading>{pageData.team.subheading}</Subheading>
          <SectionHeader>{pageData.team.heading}</SectionHeader>
        </TeamTitleWrapper>

        <Team
          members={members.map(
            addElementWithBackgroundImageToObject(teamImages)
          )}
        />
      </CenteredContentWrapper>

        <BlogWrapper id="blog">
            <Subheading>BLOG</Subheading>
            <SectionHeader>Our insights & news</SectionHeader>
        </BlogWrapper>

      <div style={{ maxWidth: '1140px', margin: '0 auto 60px', padding: '0 24px' }}>
        <Posts small />
      </div>

      <TextToutFullwidth {...contactUsFooterProps} />
    </>
  );
};

export const WhoWeAre = () => (
  <StaticQuery
    query={graphql`
      query {
        building: file(relativePath: { eq: "building2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pattern: file(relativePath: { eq: "triangular_pattern.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        team: allFile(filter: { absolutePath: { regex: "/team/" } }) {
          nodes {
            base
            childImageSharp {
              fluid(quality: 90, maxWidth: 500, grayscale: true) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        post1: file(relativePath: { eq: "diamonds.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        post2: file(relativePath: { eq: "stylish_old_mobile.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        post3: file(relativePath: { eq: "albert_einstein_blackboard.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return <Page data={data} />;
    }}
  />
);

const Spacing = styled.div`
  height: ${spacing.mobile.betweenSections}px;
  width: 100%;

  @media ${device.tablet} {
    height: ${spacing.desktop.betweenSections}px;
  }
`;

const ToutWrapper = styled(ConfettiBigSectionWrapper)`
  padding-top: 55px;
`;

const ToutHeader = styled(PageHeader)`
  &&& {
    background-color: ${colors.AcceptanceGreenUltimatelyLight};
  }
  @media ${device.tabletVertical} {
    margin-bottom: 0;
    * {
      text-align: center;
    }
  }
`;

const ToutTextBody = styled(TextBody)`
  font-size: 20px;
  @media ${device.tabletVertical} {
    margin-bottom: 50px;
  }
`;

const ToutContent = styled.div`
  * {
    max-width: 750px !important;
  }

  @media ${device.tabletVertical} {
    * {
      text-align: center;
    }
  }
`;

const InlineHeroWithTwoColumnsHeader = styled.div`
  @media ${device.tabletVertical} {
    width: 460px;
    max-width: 50%;
    position: absolute;
    margin-top: -25px;
  }
`;

const HeroContentWrapper = styled(TwoColumnsWrapper)`
  @media ${device.mobile} {
    padding: 0 !important;
  }

  > * {
    width: 100%;
  }

  @media ${device.tabletVertical} {
    height: 450px;
    margin-bottom: -160px;
  }
  @media ${device.tablet} {
    > * {
      width: 50%;
    }
  }
`;

const TwoColumnContentNodeWrapper = styled(ContentNodeWrapper)`
  text-align: center;
  margin-bottom: 45px;

  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 0;
  }
`;

const TeamTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;

  @media ${device.tabletVertical} {
    margin-bottom: 40px;
  }

  * {
    text-align: center;

    @media ${device.tabletVertical} {
      max-width: 700px;
    }
  }
`;

const TwoColumnSectionHeader = styled(SectionHeader)`
  @media ${device.tabletVertical} {
    margin: 20px 0 0;
  }
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;
